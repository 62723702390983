<template>
  <div class="home">
    <div class="cont">
      <img class="bg" src="../assets/img/supportBG.png" alt="">
      <p class="name" v-if="detail.policy">{{ detail.policyPlan.planName }}</p>
      <p class="date" v-if="detail.policy">
        {{ detail.policy.securityStartTime }} 至
        {{ detail.policy.securityEndTime }}
      </p>
      <div class="msg">
        <img style="width:100%;margin-top:20px;" v-if="detail.policyPlan" :src="detail.policyPlan.planDetails" alt="">
      </div>
    </div>

    <!-- <p v-if="detail.policyPlan">{{detail.policyPlan.planName}}</p>
    <p v-if="detail.policy">{{detail.policy.securityStartTime}} 至 {{detail.policy.securityEndTime}}</p>
    <img v-if="detail.policyPlan" :src="detail.policyPlan.planDetails" alt="">-->
    <supportConcept />
  </div>
</template>

<script>
import supportConcept from "../components/insuranceConsultation";
import { supportConceptDetail } from "../utils/api";
export default {
  data() {
    return {
      detail: {},
    };
  },
  components: { supportConcept },
  created() {},
  methods: {
    //保单数据
    async supportConceptDetail() {
      this.$toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0,
      });
      await supportConceptDetail().then((res) => {
        console.log(res);
        this.detail = res.data;
      });
    },
  },
  async mounted() {
    this.supportConceptDetail();
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  padding: 12px;
  .cont {
    width: 100%;
    background: #ffffff rgba(255, 255, 255, 0.03);
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: 20px 15px;
    position: relative;
    .bg{
      position: absolute;
      top: 18px;
      right: 14px;
      z-index: -100;
    }
    .name {
      font-size: 20px;
      font-weight: 600;
      color: #333333;
      line-height: 20px;
    }
    .date {
      font-size: 12px;
      font-weight: 400;
      color: #666666;
      line-height: 17px;
      margin-top: 15px;
    }
    .msg{

    }
  }
  // p{
  //   font-size: 14px;
  //   line-height: 14px;
  //   margin-bottom: 10px;
  // }
  // img{
  //   width: 100%;
  // }
}
</style>